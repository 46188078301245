import { EventAction, EventDetails, EventObjectType } from "@accurx/shared";
import {
    getPatientResponseBaseProps,
    sendPatientPortalEvent,
} from "./AnalyticsBase.utils";

export interface ConversationAnalyticsProps {
    /* Application url provided to the user */
    urlId: string;
}

export enum AttachmentType {
    Image = "Image",
    Document = "Document",
}

type AttachmentSentDetails = {
    isLastMessageSent: boolean;
    anonymousPatientId: string;
    userId: string;
    userOrganisationId: string;
    documentId: string;
    attachmentType: AttachmentType;
};

type SelfBookButtonDetails = {
    appointmentType: string | null;
    slotName: string;
    anonymousPatientId: string;
    userId: string;
    userOrganisationId: string;
};

type UpdatedRudderstackAnalyticsData = {
    anonymousPatientId: string;
    userId: string;
    userOrganisationId: string;
};

type ConversationsWithUpdatedAnalyticsProps = ConversationAnalyticsProps &
    UpdatedRudderstackAnalyticsData;

type ConversationAttachmentAnalyticsProps = ConversationAnalyticsProps &
    AttachmentSentDetails;

type ConversationEventType = EventDetails & ConversationAnalyticsProps;

type ConversationSelfBookAppointmentProps = ConversationAnalyticsProps &
    SelfBookButtonDetails;

const sendPatientPortalConversationEvent = async ({
    object,
    objectType,
    action,
    properties,
    urlId,
}: ConversationEventType) => {
    return await sendPatientPortalEvent({
        object: object,
        objectType: objectType,
        action: action,
        properties: {
            ...properties,
            ...getPatientResponseBaseProps(urlId),
        },
    });
};

/**
 * Triggers on submitting a DOB in the form on the auth page
 * @param properties urlId
 *
 * ```
 * Object name: TrackConversationAuth
 * Event name: trackConversationAuthButtonClick
 * Object Type: Button
 * Trigger: Click
 * ```
 *
 * @category Auth event
 */
export const trackConversationAuthButtonClick = async ({
    urlId,
    anonymousPatientId,
    userId,
    userOrganisationId,
}: ConversationsWithUpdatedAnalyticsProps) => {
    sendPatientPortalConversationEvent({
        object: "ConversationAuth",
        objectType: EventObjectType.Button,
        action: EventAction.Click,
        urlId,
        properties: {
            anonymousPatientId,
            userId,
            userOrganisationId,
        },
    });
};

/**
 * Triggers when a patient starts to enter their DOB on the auth page
 * @param properties urlId
 *
 *  ```
 * Object name: trackStartDOBAuth
 * Event name: trackStartDOBAuthInputFocus
 * Object Type: Input
 * Trigger: Focus
 * ```
 * @category Auth event
 */
export const trackStartDOBAuth = async ({
    urlId,
    anonymousPatientId,
    userId,
    userOrganisationId,
}: ConversationsWithUpdatedAnalyticsProps) => {
    sendPatientPortalConversationEvent({
        object: "ConversationAuthStart",
        objectType: EventObjectType.Input,
        action: EventAction.Focus,
        urlId,
        properties: {
            anonymousPatientId,
            userId,
            userOrganisationId,
        },
    });
};

/**
 * Triggers on clicking the button to attach a file from the conversation page
 * @param properties urlId
 *
 * ```
 * Object name: trackConversationMessage
 * Event name: trackConversationMessageButtonClick
 * Object Type: Button
 * Trigger: Click
 * ```
 *
 * @category Conversation event
 */

export const trackConversationMessageAttach = async ({
    urlId,
    anonymousPatientId,
    userId,
    userOrganisationId,
}: ConversationsWithUpdatedAnalyticsProps) => {
    sendPatientPortalConversationEvent({
        object: "ConversationMessageAttach",
        objectType: EventObjectType.Button,
        action: EventAction.Click,
        urlId,
        properties: {
            anonymousPatientId,
            userId,
            userOrganisationId,
        },
    });
};

/**
 * Triggers on clicking send on a message from the conversation page
 * @param properties urlId
 *
 * ```
 * Object name: trackConversationMessageSend
 * Event name: trackConversationMessageSendButtonClick
 * Object Type: Button
 * Trigger: Click
 * ```
 *
 * @category Conversation event
 */
export const trackConversationMessageSend = async ({
    urlId,
    anonymousPatientId,
    userId,
    userOrganisationId,
}: ConversationsWithUpdatedAnalyticsProps) => {
    sendPatientPortalConversationEvent({
        object: "ConversationMessageSend",
        objectType: EventObjectType.Button,
        action: EventAction.Click,
        urlId,
        properties: {
            anonymousPatientId,
            userId,
            userOrganisationId,
        },
    });
};

/**
 * Triggers when the conversation attachment view button is click
 * @param properties urlId and isLastMessageSent.
 *
 * ```
 * Object name: trackPatientConversationAttachmentView
 * Event name: trackPatientConversationAttachmentViewClick
 * Object Type: Button
 * Trigger: Click
 * Custom properties: isLastMessageSent
 * ```
 *
 * @category Conversation event
 */
const trackPatientConversationAttachmentViewClick = async ({
    urlId,
    isLastMessageSent,
    anonymousPatientId,
    userId,
    userOrganisationId,
    documentId,
    attachmentType,
}: ConversationAttachmentAnalyticsProps) => {
    sendPatientPortalConversationEvent({
        object: "ConversationPatientAttachmentView",
        objectType: EventObjectType.Button,
        action: EventAction.Click,
        urlId,
        properties: {
            isLastMessageSent,
            anonymousPatientId,
            userId,
            userOrganisationId,
            documentId,
            attachmentType,
        },
    });
};

/**
 * Triggers when the conversation attachment download button is click
 * @param properties urlId and isLastMessageSent.
 *
 * ```
 * Object name: trackPatientConversationAttachmentDownload
 * Event name: trackPatientConversationAttachmentDownloadClick
 * Object Type: Button
 * Trigger: Click
 * Custom properties: isLastMessageSent
 * ```
 *
 * @category Conversation event
 */
const trackPatientConversationAttachmentDownloadClick = async ({
    urlId,
    anonymousPatientId,
    userId,
    userOrganisationId,
    isLastMessageSent,
    documentId,
    attachmentType,
}: ConversationAttachmentAnalyticsProps) => {
    await sendPatientPortalConversationEvent({
        object: "ConversationPatientAttachmentDownload",
        objectType: EventObjectType.Button,
        action: EventAction.Click,
        urlId,
        properties: {
            isLastMessageSent,
            anonymousPatientId,
            userId,
            userOrganisationId,
            documentId,
            attachmentType,
        },
    });
};

/**
 * Triggers when the conversation attachment view button is click
 * @param properties urlId and isLastMessageSent.
 * ```
 * Object name: trackClinicianConversationAttachmentView
 * Event name: trackClinicianConversationAttachmentViewClick
 * Object Type: Button
 * Trigger: Click
 * Custom properties: isLastMessageSent
 * ```
 *
 * @category Conversation event
 */
export const trackClinicianConversationAttachmentViewClick = async ({
    urlId,
    anonymousPatientId,
    userId,
    userOrganisationId,
    isLastMessageSent,
    documentId,
    attachmentType,
}: any) => {
    sendPatientPortalConversationEvent({
        object: "ConversationClinicianAttachmentView",
        objectType: EventObjectType.Button,
        action: EventAction.Click,
        urlId,
        properties: {
            isLastMessageSent,
            anonymousPatientId,
            userId,
            userOrganisationId,
            documentId,
            attachmentType,
        },
    });
};

/**
 * Triggers when the conversation attachment view button is click
 * @param properties urlId and isLastMessageSent
 *
 * ```
 * Object name: trackClinicianConversationAttachmentDownload
 * Event name: trackClinicianConversationAttachmentDownloadClick
 * Object Type: Button
 * Trigger: Click
 * Custom properties: isLastMessageSent
 * ```
 *
 * @category Conversation event
 */
const trackClinicianConversationAttachmentDownloadClick = async ({
    urlId,
    anonymousPatientId,
    userId,
    userOrganisationId,
    isLastMessageSent,
    documentId,
    attachmentType,
}: ConversationAttachmentAnalyticsProps) => {
    await sendPatientPortalConversationEvent({
        object: "ConversationClinicanAttachmentDownload",
        objectType: EventObjectType.Button,
        action: EventAction.Click,
        urlId,
        properties: {
            isLastMessageSent,
            anonymousPatientId,
            userId,
            userOrganisationId,
            documentId,
            attachmentType,
        },
    });
};

/**
 * Triggers when the conversation attachment view button is click
 * @param properties isPatientMessage and attachmentTrackingDetails.
 *
 * ```
 * Object name: trackAttachmentView
 * Event name: trackAttachmentViewClick
 * Object Type: Button
 * Trigger: Click
 * Custom properties: isLastMessageSent, isPatientMessage
 * ```
 *
 * @category Conversation event
 */
export const trackViewClick = (
    isPatientMessage: boolean,
    attachmentTrackingDetails: {
        urlId: string;
        isLastMessageSent: boolean;
        anonymousPatientId: string;
        userId: string;
        userOrganisationId: string;
        documentId: string;
        attachmentType: AttachmentType;
    },
) => {
    return isPatientMessage
        ? trackPatientConversationAttachmentViewClick(attachmentTrackingDetails)
        : trackClinicianConversationAttachmentViewClick(
              attachmentTrackingDetails,
          );
};

/**
 * Triggers when the conversation attachment download button is click
 * @param properties isPatientMessage and attachmentTrackingDetails
 *
 * ```
 * Object name: trackAttachmentDownload
 * Event name: trackAttachmentDownloadClick
 * Object Type: Button
 * Trigger: Click
 * Custom properties: isLastMessageSent, isPatientMessage
 * ```
 *
 * @category Conversation event
 */
export const trackDownloadClick = async (
    isPatientMessage: boolean,
    attachmentTrackingDetails: {
        urlId: string;
        isLastMessageSent: boolean;
        anonymousPatientId: string;
        userId: string;
        userOrganisationId: string;
        documentId: string;
        attachmentType: AttachmentType;
    },
) => {
    return isPatientMessage
        ? await trackPatientConversationAttachmentDownloadClick(
              attachmentTrackingDetails,
          )
        : await trackClinicianConversationAttachmentDownloadClick(
              attachmentTrackingDetails,
          );
};

type ConversationAnalyticsQuestionnaireProps = ConversationAnalyticsProps & {
    /* The name of the florey questionnaire */
    questionnaireName: string;
    anonymousPatientId: string;
    userId: string;
    userOrganisationId: string;
};

type ConversationAnalyticsNhsLoginProps = ConversationAnalyticsProps & {
    /* Is on the landing page or the homepage */
    isLandingPage: boolean;
    anonymousPatientId: string;
    userId: string;
    userOrganisationId: string;
};

/**
 * Triggers when the conversation florey questionnaire button is clicked
 * @param properties urlId, questionnaireName
 *
 * ```
 * Object name: trackFillOutQuestionnaireClick
 * Event name: trackFillOutQuestionnaireClick
 * Object Type: Button
 * Trigger: Click
 * Custom properties: urlId
 * ```
 *
 * @category Conversation event
 */
export const trackFillOutQuestionnaireClick = async ({
    urlId,
    questionnaireName,
    anonymousPatientId,
    userId,
    userOrganisationId,
}: ConversationAnalyticsQuestionnaireProps) => {
    sendPatientPortalConversationEvent({
        object: "ConversationQuestionnaireAnswer",
        objectType: EventObjectType.Button,
        action: EventAction.Click,
        properties: {
            questionnaireName: questionnaireName,
            anonymousPatientId,
            userId,
            userOrganisationId,
        },
        urlId,
    });
};

/**
 * Triggers when a conversation item is clicked from the conversation list
 * @param properties urlId
 *
 * ```
 * Object name: trackConversationItemClick
 * Event name: trackConversationItemClick
 * Object Type: ListItem
 * Trigger: Click
 * Custom properties: urlId
 * ```
 *
 * @category Conversation event
 */
export const trackConversationItemClick = async ({
    urlId,
    anonymousPatientId,
    userId,
    userOrganisationId,
}: ConversationsWithUpdatedAnalyticsProps) => {
    sendPatientPortalConversationEvent({
        object: "ConversationItem",
        objectType: EventObjectType.ListItem,
        action: EventAction.Click,
        urlId,
        properties: {
            anonymousPatientId,
            userId,
            userOrganisationId,
        },
    });
};

/**
 * Triggers when the "View all messages" is clicked on the thread
 * @param properties urlId
 *
 * ```
 * Object name: ConversationListView
 * Event name: ViewConversationListButtonClick
 * Object Type: Button
 * Trigger: Click
 * Custom properties: urlId
 * ```
 *
 * @category Conversation event
 */
export const trackViewConversationListClick = async ({
    urlId,
    anonymousPatientId,
    userId,
    userOrganisationId,
}: ConversationsWithUpdatedAnalyticsProps) => {
    sendPatientPortalConversationEvent({
        object: "ConversationListView",
        objectType: EventObjectType.Button,
        action: EventAction.Click,
        urlId,
        properties: {
            anonymousPatientId,
            userId,
            userOrganisationId,
        },
    });
};

/**
 * Triggers when the "View NHS setting" is clicked on the thread
 * @param properties urlId
 *
 * ```
 * Object name: NhsSettingView
 * Event name: NhsSettingViewButtonClick
 * Object Type: Button
 * Trigger: Click
 * Custom properties: urlId
 * ```
 *
 * @category Conversation event
 */
export const trackNhsSettingViewClick = async ({
    urlId,
    anonymousPatientId,
    userId,
    userOrganisationId,
}: ConversationsWithUpdatedAnalyticsProps) => {
    sendPatientPortalConversationEvent({
        object: "NhsSettingView",
        objectType: EventObjectType.Button,
        action: EventAction.Click,
        urlId,
        properties: {
            anonymousPatientId,
            userId,
            userOrganisationId,
        },
    });
};

/**
 * Triggers when the NHS button is clicked
 * @param properties urlId
 *
 * ```
 * Object name: NhsLogin
 * Event name: NhsLoginButtonClick
 * Object Type: Button
 * Trigger: Click
 * Custom properties: urlId
 * ```
 *
 * @category Conversation event
 */
export const trackNhsLoginClick = async ({
    urlId,
    isLandingPage,
    anonymousPatientId,
    userId,
    userOrganisationId,
}: ConversationAnalyticsNhsLoginProps) => {
    sendPatientPortalConversationEvent({
        object: "NhsLogin",
        objectType: EventObjectType.Button,
        action: EventAction.Click,
        properties: {
            isLandingPage: isLandingPage,
            anonymousPatientId,
            userId,
            userOrganisationId,
        },
        urlId,
    });
};

/**
 * Triggers when the book appointment button is click
 * @param properties urlId
 *
 * ```
 * Object name: trackConversationBookingAcceptStart
 * Event name: trackConversationBookingAcceptStartClick
 * Object Type: Button
 * Trigger: Click
 * Custom properties: appointmentType, slotName
 * ```
 *
 * @category Conversation event
 */
const trackConversationBookingAcceptStartClick = async ({
    urlId,
    appointmentType,
    slotName,
    anonymousPatientId,
    userId,
    userOrganisationId,
}: ConversationSelfBookAppointmentProps) => {
    await sendPatientPortalConversationEvent({
        object: "ConversationBookingAcceptStart",
        objectType: EventObjectType.Button,
        action: EventAction.Click,
        urlId,
        properties: {
            appointmentType,
            slotName,
            anonymousPatientId,
            userId,
            userOrganisationId,
        },
    });
};

/**
 * Triggers when the decline appointment button is click
 * @param properties urlId
 *
 * ```
 * Object name: trackConversationBookingDeclineStart
 * Event name: trackConversationBookingDeclineStartClick
 * Object Type: Button
 * Trigger: Click
 * Custom properties: appointmentType, slotName
 * ```
 *
 * @category Conversation event
 */
const trackConversationBookingDeclineStartClick = async ({
    urlId,
    appointmentType,
    slotName,
    anonymousPatientId,
    userId,
    userOrganisationId,
}: ConversationSelfBookAppointmentProps) => {
    await sendPatientPortalConversationEvent({
        object: "ConversationBookingDeclineStart",
        objectType: EventObjectType.Button,
        action: EventAction.Click,
        urlId,
        properties: {
            appointmentType,
            slotName,
            anonymousPatientId,
            userId,
            userOrganisationId,
        },
    });
};

/**
 * Triggers when the selfbook appointment button is clicked
 * @param properties isAppointmentDeclined and selfBookAppointmentDetails
 *
 * ```
 * Object name: trackSelfBookStart
 * Event name: trackSelfBookStartClick
 * Object Type: Button
 * Trigger: Click
 * Custom properties: isAppointmentDeclined
 * ```
 *
 * @category Conversation event
 */
export const trackSelfBookStartClick = async (
    isAppointmentDeclined: boolean,
    selfBookAppointmentDetails: {
        urlId: string;
        appointmentType: string | null;
        slotName: string;
        anonymousPatientId: string;
        userId: string;
        userOrganisationId: string;
    },
) => {
    return isAppointmentDeclined
        ? await trackConversationBookingDeclineStartClick(
              selfBookAppointmentDetails,
          )
        : await trackConversationBookingAcceptStartClick(
              selfBookAppointmentDetails,
          );
};

/**
 * Triggers when the add to calendar button button is clicked
 * @param properties urlId
 *
 * ```
 * Object name: trackConversationAddToCalendarClick
 * Event name: trackConversationAddToCalendarClick
 * Object Type: Button
 * Trigger: Click
 * Custom properties: appointmentType, slotName
 * ```
 *
 * @category Conversation event
 */
export const trackConversationAddToCalendarClick = async (
    urlId: string,
    appointmentType: string,
    anonymousPatientId: string,
    userId: string,
    userOrganisationId: string,
) => {
    await sendPatientPortalConversationEvent({
        object: "ConversationBookingAddToCalendar",
        objectType: EventObjectType.Button,
        action: EventAction.Click,
        urlId,
        properties: {
            appointmentType,
            anonymousPatientId,
            userId,
            userOrganisationId,
        },
    });
};
