import { ErrorBoundary } from "@accurx/shared";
import {
    contextState,
    ConversationProvider,
} from "../context/ConversationContext";

type ConversationLayoutProps = { children: React.ReactNode };

export default function ConversationLayout({
    children,
}: ConversationLayoutProps): JSX.Element {
    return (
        <ErrorBoundary>
            <ConversationProvider value={contextState()}>
                {children}
            </ConversationProvider>
        </ErrorBoundary>
    );
}
