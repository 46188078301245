import { createContext, useContext, useState } from "react";
import {
    Conversation,
    RudderstackAnalyticsData,
} from "../api/PatientServerDTOs";

export type ConversationState = {
    authenticatedWithNhs: boolean;
    setAuthenticatedWithNhs: (authenticatedWithNhs: boolean) => void;
    authenticatedWithDob: boolean;
    setAuthenticatedWithDob: (authenticatedWithDob: boolean) => void;
    conversationThread: Conversation | null;
    setConversationThread: (conversation: Conversation) => void;
    rudderstackAnalyticsData: RudderstackAnalyticsData;
    setRudderstackAnalyticsData: (
        analyticsData: RudderstackAnalyticsData,
    ) => void;
};

export const defaultState: ConversationState = {
    authenticatedWithNhs: false,
    setAuthenticatedWithNhs: () => {},
    authenticatedWithDob: false,
    setAuthenticatedWithDob: () => {},
    conversationThread: null,
    setConversationThread: () => {},
    rudderstackAnalyticsData: {
        anonymousId: "00000000-0000-0000-0000-000000000000",
        organisationId: "",
        userId: "00000000-0000-0000-0000-000000000000",
    },
    setRudderstackAnalyticsData: () => {},
};

export const ConversationContext = createContext<ConversationState | undefined>(
    undefined,
);
ConversationContext.displayName = "ConversationContext";

const ConversationProvider = ConversationContext.Provider;

const useConversation = (): ConversationState => {
    const context = useContext(ConversationContext);

    // If we're on the client-side, ensure that anything trying to use the useConversation hook is wrapped in the provider
    if (typeof window !== "undefined") {
        if (context === undefined) {
            throw new Error(
                "This component is a ConversationConsumer, it must be used within an ConversationProvider",
            );
        }

        return context;
    }

    // If we're on the server-side then don't throw an error yet the context is only expected to update once client-side
    // Instead just return a default value for now
    return context ?? defaultState;
};

const contextState = () => {
    const [authenticatedWithNhs, setAuthenticatedWithNhs] =
        useState<boolean>(false);
    const [authenticatedWithDob, setAuthenticatedWithDob] =
        useState<boolean>(false);
    useState<boolean>(false);
    const [conversationThread, setConversationThread] =
        useState<Conversation | null>(null);
    const [rudderstackAnalyticsData, setRudderstackAnalyticsData] =
        useState<RudderstackAnalyticsData>({
            anonymousId: "00000000-0000-0000-0000-000000000000",
            organisationId: "",
            userId: "00000000-0000-0000-0000-000000000000",
        } as RudderstackAnalyticsData);

    const handleSetAuthenticatedWithNhs = (authenticatedWithNhs: boolean) => {
        setAuthenticatedWithNhs(authenticatedWithNhs);
    };
    const handleSetAuthenticatedWithDob = (authenticatedWithDob: boolean) => {
        setAuthenticatedWithDob(authenticatedWithDob);
    };
    const handleSetConversationThread = (conversation: Conversation) => {
        setConversationThread(conversation);
    };
    const handleSetRudderstackAnalyticsData = (
        rudderstackAnalyticsData: RudderstackAnalyticsData,
    ) => {
        setRudderstackAnalyticsData(rudderstackAnalyticsData);
    };

    return {
        ...defaultState,
        authenticatedWithNhs,
        setAuthenticatedWithNhs: handleSetAuthenticatedWithNhs,
        authenticatedWithDob,
        setAuthenticatedWithDob: handleSetAuthenticatedWithDob,
        conversationThread,
        setConversationThread: handleSetConversationThread,
        rudderstackAnalyticsData,
        setRudderstackAnalyticsData: handleSetRudderstackAnalyticsData,
    };
};

export { ConversationProvider, useConversation, contextState };
