import {
    AnalyticsProperties,
    EventAction,
    EventDetails,
    EventObjectType,
    EventUserType,
    CookieFreeTrackEvent,
    ClearRudderstackCookies,
} from "@accurx/shared";
import { getCookieBannerResponse } from "@accurx/design";
import { useEffect } from "react";
import { createHash } from "crypto";

const isTrackingEnabled = (): boolean =>
    getCookieBannerResponse() === "accepted";

// All portal events are done by patients, so we add eventUserType patient to them all
export const sendPatientPortalEvent = async ({
    object,
    objectType,
    action,
    properties,
}: EventDetails) => {
    if (!isTrackingEnabled()) return;
    return await CookieFreeTrackEvent({
        object: object,
        objectType: objectType,
        action: action,
        properties: {
            ...properties,
            eventUserType: EventUserType.Patient,
        },
    });
};

const trackPageView = async (
    pageName: string,
    properties?: AnalyticsProperties,
): Promise<void> => {
    sendPatientPortalEvent({
        object: pageName,
        objectType: EventObjectType.Page,
        action: EventAction.View,
        properties,
    });
};

export const usePageLoadEvent = (
    pageName: string,
    properties?: AnalyticsProperties,
): void => {
    useEffect(() => {
        ClearRudderstackCookies(); // Temp - this can be removed once we are confident all cookies have been removed
        trackPageView(pageName, properties);
    }, [pageName]);
};

/*
 * This has been moved here from `@accurx/shared` so we can remove the
 * shared library's dependency on the node `crypto` module - which
 * is causing a significant increase in bundle size. It should be possible to
 * replace the use of `crypto/createHash` with the inbuilt browser support
 * of `crypto.subtle` - but for now moving this here to alleviate an immediate
 * problem with fleming-client.
 */
export const getPatientResponseBaseProps = (
    urlId: string,
    isUserLoggedIn?: boolean,
): AnalyticsProperties => {
    // hashing this to avoid any risk of exposing the URL ID in analytics.
    const hashedUrlId = createHash("sha256").update(urlId).digest("hex");
    return {
        urlId: hashedUrlId,
        eventVersion: 3,
        eventUserType: EventUserType.Patient,
        userIsLoggedIn: isUserLoggedIn,
    };
};
