import React, { ReactNode } from "react";
import styled from "styled-components";
import { Tokens } from "@accurx/design";
import { StyledPaddingBreakPoints } from "./shared/CommonStyles.styles";
interface PageHeaderProps {
    headerContent: ReactNode;
}

const StyledHeader = styled.header`
    background-color: ${Tokens.COLOURS.primary.green[100]};
    min-height: 64px;
    display: flex;
    align-items: center;
    color: ${Tokens.COLOURS.greyscale.white};
    padding: ${Tokens.SIZES[1]} ${Tokens.SIZES[2]};
    margin: 0;
    ${StyledPaddingBreakPoints};

    @media (min-width: ${Tokens.BREAKPOINTS.xl}) {
        display: flex;
        justify-content: center;
        padding: 0 329px 0 0;
    } ;
`;

// Adds page metadata and the navbar
const PageHeader = ({ headerContent }: PageHeaderProps): JSX.Element => {
    return (
        <>
            <StyledHeader>{headerContent}</StyledHeader>
        </>
    );
};

export default PageHeader;
