export const PAGE_TITLES = {
    appointment: "Your Appointment",
    authenticate: "Authenticate with date of birth or NHS login",
    authenticateCancelled: "This link has been cancelled",
    authenticateLocked: "This link has been locked",
    authenticateError: "Error loading conversation details",
    consentDeclined: "Error: Agree to share your NHS information",
    conversations: "List of your healthcare conversations",
    conversationThread: "A conversation between you and your HCP",
    conversationThreadImagePreviewer: "An image you have clicked on to view",
    landingPage: "Access conversation about your healthcare",
};
